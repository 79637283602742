<template>
	<div class="mb-2">
		<div class="v-label mb-1" v-if="label">{{ label }}</div>
		<date-picker
			:value="datetime"
			:type="pickerType"
			:showSecond="false"
			:minute-options="[0, 15, 30, 45]"
			:format="format"
			@input="setDate"
		></date-picker>
	</div>
</template>
<script>
import firebase from "firebase";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
	name: "MwDatePicker",
	props: {
		value: { type: Object },
		label: { type: String },
		format: { type: String, default: "DD/MM/YYYY HH:mm" },
		pickerType: { type: String, default: "datetime" },
	},
	data() {
		return {};
	},
	computed: {
		datetime() {
			if (this.value) {
				if (this.value.toDate) {
					return this.value.toDate();
				} else {
					return new Date(this.value);
				}
			}
			return null;
		},
	},
	components: { DatePicker },
	methods: {
		setDate(v) {
			let d = firebase.firestore.Timestamp.fromDate(v);
			this.$emit("input", d);
		},
	},
};
</script>
